import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> +[PTConvert ToEpub:out_path:]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTConvert FromCAD:in_filename:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> e_ptDefaultSnapMode</div>
<div class="difference"><span class="status added">Added</span> e_ptPointOnLine</div>
<div class="difference"><span class="status added">Added</span> e_ptLineMidpoint</div>
<div class="difference"><span class="status added">Added</span> e_ptLineIntersection</div>
<div class="difference"><span class="status added">Added</span> e_ptPathEndpoint</div>
<div class="difference"><span class="status added">Added</span> PTGeometryCollectionSnappingMode</div>
<div class="difference"><span class="status added">Added</span> PTGeometryCollection</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection SnapToNearest:y:mode:]</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection SnapToNearestPixel:y:dpi:mode:]</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection init]</div>
<div class="difference"><span class="status added">Added</span> -[PTGeometryCollection initWithOther:]</div>
<div class="difference"><span class="status added">Added</span> PTCADConvertOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions GetAutoRotate]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions SetAutoRotate:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions GetLineWeight]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions SetLineWeight:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions GetPageHeight]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions SetPageHeight:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions GetPageWidth]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions SetPageWidth:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions GetRasterDPI]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions SetRasterDPI:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions AddSheets:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions init]</div>
<div class="difference"><span class="status added">Added</span> PTCADModule</div>
<div class="difference"><span class="status added">Added</span> -[PTCADModule getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADModule initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADModule setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADModule dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTCADModule IsModuleAvailable]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADModule init]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert ToEpub:out_path:html_options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert FromCAD:in_filename:opts:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView GetAnnotationListAt:y1:x2:y2:]</div>
<div class="difference"><span class="status added">Added</span> PTWebFontDownloader</div>
<div class="difference"><span class="status added">Added</span> -[PTWebFontDownloader getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTWebFontDownloader initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTWebFontDownloader setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTWebFontDownloader dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTWebFontDownloader IsAvailable]</div>
<div class="difference"><span class="status added">Added</span> +[PTWebFontDownloader EnableDownloads]</div>
<div class="difference"><span class="status added">Added</span> +[PTWebFontDownloader DisableDownloads]</div>
<div class="difference"><span class="status added">Added</span> +[PTWebFontDownloader PreCacheAsync]</div>
<div class="difference"><span class="status added">Added</span> +[PTWebFontDownloader ClearCache]</div>
<div class="difference"><span class="status added">Added</span> +[PTWebFontDownloader SetCustomWebFontURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTWebFontDownloader init]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetAnnotationListAt:y1:x2:y2:]</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlPagePresentationModeDidChangeNotification</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlPagePresentationModeUserInfoKey</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTBookmarkManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager bookmarksFromJSONString:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager JSONStringFromBookmarks:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager importBookmarksForDoc:fromJSONString:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmarkManager exportBookmarksFromDoc:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDiffViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTDiffViewControllerDelegate diffViewController:didCreateDiffFileAtURL:]</div>
<table>
<tr><th></th><th>Optional</th></tr>
<tr><th>From</th><td>Required</td></tr>
<tr><th>To</th><td>Optional</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTDiffViewControllerDelegate diffViewController:didFailToCompareDocumentsWithError:]</div>
<table>
<tr><th></th><th>Optional</th></tr>
<tr><th>From</th><td>Required</td></tr>
<tr><th>To</th><td>Optional</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTDiffViewController.firstDocumentColor</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, nullable) UIColor *firstDocumentColor</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nullable) UIColor *firstDocumentColor</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTDiffViewController.secondDocumentColor</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, nullable) UIColor *secondDocumentColor</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nullable) UIColor *secondDocumentColor</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.freehandButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.readerModeButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.freehandButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.readerModeButtonItem</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTAnnotationToolbarDelegate, PTAnnotationViewControllerDelegate, PTBookmarkViewControllerDelegate, PTCoordinatedDocumentDelegate, PTOutlineViewControllerDelegate, PTOverridable, PTPDFLayerViewControllerDelegate, PTPDFViewCtrlDelegate, PTReflowViewControllerDelegate, PTSettingsViewControllerDelegate, PTTextSearchViewControllerDelegate, PTThumbnailSliderViewDelegate, PTToolManagerDelegate</td></tr>
<tr><th>To</th><td>PTAnnotationToolbarDelegate, PTAnnotationViewControllerDelegate, PTBookmarkViewControllerDelegate, PTCoordinatedDocumentDelegate, PTDocumentViewSettingsControllerDelegate, PTOutlineViewControllerDelegate, PTOverridable, PTPDFLayerViewControllerDelegate, PTPDFViewCtrlDelegate, PTReflowViewControllerDelegate, PTTextSearchViewControllerDelegate, PTThumbnailSliderViewDelegate, PTToolManagerDelegate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController.settingsViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, strong, nonnull) PTSettingsViewController *settingsViewController</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nonnull) PTDocumentViewSettingsController *settingsViewController</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewSettings.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettings</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewSettings init]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewSettings initWithCoder:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettings.pagePresentationMode</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettings.reflowEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettings.colorPostProcessMode</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettings.colorPostProcessWhiteColor</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettings.colorPostProcessBlackColor</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettings.nightModeEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettings.pageRotation</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewSettingsController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettingsControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewSettingsControllerDelegate documentViewSettingsController:didUpdateSettings:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettingsController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettingsController.settings</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettingsController.delegate</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewSettingsController init]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTMoreItemsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTMoreItemsViewController.items</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTMoreItemsViewController</div>
<table>
<tr><th></th><th>Superclass</th></tr>
<tr><th>From</th><td>UIViewController</td></tr>
<tr><th>To</th><td>UITableViewController</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPDFLayerViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTLayerInfo.group</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, nonnull) PTGroup *group</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nullable) PTGroup *group</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTLayerInfo.state</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic) BOOL state</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, assign) BOOL state</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTReflowViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTReflowMode</div>
<div class="difference"><span class="status added">Added</span> PTReflowModeTextOnly</div>
<div class="difference"><span class="status added">Added</span> PTReflowModeTextAndRawImages</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewController.pageViewController</div>
<div class="difference"><span class="status added">Added</span> PTReflowViewController.reflowMode</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTReflowViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTOverridable</td></tr>
<tr><th>To</th><td>PTOverridable, UIPageViewControllerDataSource, UIPageViewControllerDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSearchSettingsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTextSearchMatchCaseKey</div>
<div class="difference"><span class="status added">Added</span> PTTextSearchMatchWholeWordKey</div>
<div class="difference"><span class="status added">Added</span> PTSearchSettingsViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTSearchSettingsViewControllerDelegate searchSettingsViewControllerDidToggleSearchMode:]</div>
<div class="difference"><span class="status added">Added</span> PTSearchSettingsViewController</div>
<div class="difference"><span class="status added">Added</span> PTSearchSettingsViewController.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTSelectionRectView initWithFrame:forAnnot:withAnnotEditTool:withPDFViewCtrl:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull instancetype)initWithFrame:(CGRect)frame forAnnot:(nonnull PTAnnot *)annot withAnnotEditTool:(nonnull PTAnnotEditTool *)tool withPDFViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)initWithFrame:(CGRect)frame forAnnot:(nullable PTAnnot *)annot withAnnotEditTool:(nonnull PTAnnotEditTool *)tool withPDFViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSettingsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTSettingsViewControllerDelegate</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 7.0.4. Use PTDocumentViewSettingsControllerDelegate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTSettingsViewController</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 7.0.4. Use PTDocumentViewSettingsController</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTextSearchViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTextSearchViewController.searchSettingsViewController</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTThumbnailsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTThumbnailsViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTOverridable, UICollectionViewDataSource, UICollectionViewDelegateFlowLayout</td></tr>
<tr><th>To</th><td>PTOverridable, UICollectionViewDataSource, UICollectionViewDelegateFlowLayout, UIPopoverPresentationControllerDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTTool</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTNoteEditControllerDelegate, PTOverridable, PTToolSwitching, UIGestureRecognizerDelegate, UIPopoverPresentationControllerDelegate</td></tr>
<tr><th>To</th><td>PTNoteEditControllerDelegate, PTOverridable, PTToolSwitching, UIAdaptivePresentationControllerDelegate, UIGestureRecognizerDelegate, UIPopoverPresentationControllerDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsSettingsManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.showInkInMainToolbar</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.showTextSearchInMainToolbar</div>
<div class="difference"><span class="status added">Added</span> PTToolsSettingsManager.tabsEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTUserBookmark.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTUserBookmark initWithTitle:pageNumber:]</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}